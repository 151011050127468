import { CarBaseFilterSetup } from '../lib/api/models/umbraco/content-spot';

export const HireNewCarUrlPrefix = 'ny-bil';
export const HireNewCarUmbracoPageId = 'ny-bil';
export const AvailabilityFilterKey = 'availability';
export const EquipmentFilterKey = 'filterEquipment';
export const FirstYearRegFilterKey = 'specification.firstYearOfRegistration';
export const BusinessPaymentTypeFilterKey = 'businessPaymentTypes';
export const PrivatePaymentTypeFilterKey = 'privatePaymentTypes';
export const HeightTypeFilterKey = 'specification.heightType.keyword';
export const LengthTypeFilterKey = 'specification.lengthType.keyword';
export const ModelTitleFilterKey = 'modelTitle';
export const FamilyFilterKey = 'family';
export const BrandFilterKey = 'brand.keyword';
export const CashPriceFilterKey = 'purchaseTypes.cash.basePrice';
export const FinancingPriceFilterKey = 'purchaseTypes.financing.baseMonthlyPrice';
export const HirePriceFilterKey = 'purchaseTypes.hire.baseMonthlyPrice';
export const PrivateLeasingPriceFilterKey = 'purchaseTypes.privateLeasing.baseMonthlyPrice';
export const VanLeaseingPriceFilterKey = 'operationalOrFinancialLeasingPrice';
export const VanLeasingDownpaymentFilterKey = 'operationalOrFinancialLeasingDownPayment';
export const PriceFilterKeys = [
    CashPriceFilterKey,
    FinancingPriceFilterKey,
    HirePriceFilterKey,
    PrivateLeasingPriceFilterKey,
    VanLeaseingPriceFilterKey,
];
export const PdpPurchaseTypekey = 'purchaseType';
export const Plp_Load_More_Filter_From_Key = 'plp-filter-from';
export const Plp_Load_More_No_Filter_From_Key = 'plp-no-filter-from';
export const Plp_Load_More_Filter_Product_Card_Key = 'plp-filter-product-card-id';
export const Plp_Load_More_Context_Key = 'plp-filter-context';
export const OrderAvailabilityKey = 'Order';
export const ParentColorFilterKey = 'parentColors';
export const InStockAvailabilityKey = 'InStock';
export const UsedAvailabilityKey = 'Used';
export const EngrosVanAvailabilityKey = 'Engros';
export const DemoAvailabilityKey = 'Demo';
export const LoadFromMiniBasketKey = 'HireMiniBasketLoad';
export const BrandFacetKey = 'brand.keyword';
export const lead_checkout_url = '/checkout';
export const shop_checkout_url = '/betaling';

export const Applicable_Facet_Keys = ['brand.keyword', 'modelTitle.keyword', 'bodyType.keyword', HirePriceFilterKey];
export const Car_Finder_Spots_Filter_Limit = 3;

export const FilterConfigurationKey = {
    availability: 'availability__',
    availability_demo: 'availability__Demo',
    availability_used: 'availability__Used',
    bodyType: 'bodyType__',
    modelTitle: 'modelTitle__',
    itemNumber: 'itemNumber__',
    category_car: 'category__Car',
    category_van: 'category__Van',
    locationId: 'locationId__',
    purchaseTypes_solutionTaxation_show: 'purchaseTypes.solutionTaxation.show__true',
};

export const DefaultBaseFilterSetup: CarBaseFilterSetup = {
    availabilities: ['Order', 'InStock'],
    defaultAvailability: 'Order',
    alias: 'carBaseFilter',
    defaultPaymentType: 'Leasing',
    showAsCompanyCars: false,
};

export const contactForPriceLabelText = 'Forespørg på pris';
