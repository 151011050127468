import router from 'next/router';
import { ToggleOptionType } from '../../components/shared/toggle-ui/toggle-ui.component';
import { AvailabilityLabel, DealershipInformationPage } from '../../lib/api/models/umbraco';
import { FilterGroupTextConfig } from '../../lib/api/models/umbraco/content-spot';
import { hesselViewModels } from '../../lib/view-models';
import { FacetGroup, FacetOption, OwnershipMode } from '../../lib/view-models/vehicle';
import { formatPrice } from './price.helper';
import { isNullOrEmpty } from './text-convert.helpers';
import { getQueryStringParams } from './url.helper';
import { ProductListApiResponse, VehiclePurchaseType } from '../../lib/api/models/hessel-api/hire';
import { Abbreviations } from '../../constants/units-and-abbreviations';
import {
    AvailabilityFilterKey,
    BrandFilterKey,
    FamilyFilterKey,
    FirstYearRegFilterKey,
    ModelTitleFilterKey,
    PdpPurchaseTypekey,
} from '../../constants/site-consts';
import { getDefaultSelectedTab } from '../../lib/state/hessel-site/product-details/selected-tab.helper';

type sortingOptions = {
    selectedSorting: string;
    defaultSorting: string;
};

export const getActiveFiltersFromQuery = (query: string | string[], filters?: FacetGroup[]): string[] => {
    let returnValue = [];
    if (Array.isArray(query)) {
        returnValue = [...query.map((x) => decodeURIComponent(x))];
    } else {
        returnValue = [decodeURIComponent(query)];
    }
    const availabilityFilter = filters ? filters.find((x) => x.key === AvailabilityFilterKey)?.facetOptions.find((x) => x.selected) : undefined;
    if (availabilityFilter) {
        returnValue = [availabilityFilter.filterQueryValue, ...returnValue];
    }
    return [...new Set(returnValue.filter((x) => x.length > 0))];
};

export const pushActiveFiltersToUrl = (activeFilters: string[], page?: string): void => {
    updateUrlWithQueries(undefined, activeFilters, page);
};

export const getActiveFiltersUrl = (activeFilters: string[]): string => {
    const baseUrl = window.location.href.split('?')[0];
    if (activeFilters.length > 0) {
        let newUrl = baseUrl + '?';
        for (const filter of activeFilters) {
            newUrl = newUrl + 'filter=' + filter;
            if (filter !== activeFilters[activeFilters.length - 1]) {
                newUrl = newUrl + '&';
            }
        }
        return newUrl;
    }

    return baseUrl;
};

export const getActiveFiltersQueryParams = (activeFilters: string[]): string => {
    if (activeFilters.length > 0) {
        let newUrl = '?';
        for (const filter of activeFilters) {
            newUrl = newUrl + 'filter=' + filter;
            if (filter !== activeFilters[activeFilters.length - 1]) {
                newUrl = newUrl + '&';
            }
        }
        return newUrl;
    }

    return '';
};

export const getMinAndMaxIntervalValues = (options: FacetOption[], groupKey: string, separator: string): [string, string] => {
    const selectedOptions = options.filter((x) => x.selected);
    if (selectedOptions.length === 2) {
        const min = selectedOptions[0].filterQueryValue;
        const max = selectedOptions[1].filterQueryValue;
        return [min, max];
    } else if (selectedOptions.length === 1) {
        const allParams = getQueryStringParams(window.location.search);
        const filterParam = allParams.find((x) => x.value.startsWith(`${groupKey}__`));
        const intervalValues = filterParam?.value.split('__')[1];
        const minMax = intervalValues?.split(separator);
        if (minMax) return [minMax[0], minMax[1]];
    }
    return ['0', '0'];
};

export const generatePdpUrl = (
    productCard: hesselViewModels.ProductCard,
    isCompanyCar = false,
    ownershipMode?: OwnershipMode,
    vehicleType?: 'Car' | 'Van'
): string => {
    if (productCard.type !== 'detailed') {
        return '';
    }
    const necessaryCarDate = {
        purchaseTypes: productCard.purchaseTypes || ({} as VehiclePurchaseType),
        brand: productCard.brand,
        vehicleType: vehicleType,
    } as hesselViewModels.ProductDetails;

    const initialPurchaseType = ownershipMode ? getDefaultSelectedTab(ownershipMode, necessaryCarDate, isCompanyCar) : undefined;
    let purchaseTypeQuery = '';
    if (initialPurchaseType) {
        purchaseTypeQuery = `${PdpPurchaseTypekey}=${encodeURI(initialPurchaseType)}`;
    }

    if (productCard.availability === 'Order') {
        return `${`${productCard.url}${isCompanyCar ? '?company=true' : ''}`.toLocaleLowerCase()}${
            purchaseTypeQuery ? `${isCompanyCar ? '&' : '?'}${purchaseTypeQuery}` : ''
        }`;
    }

    if (productCard.availability === 'InStock') {
        return isCompanyCar
            ? `${productCard.url.toLocaleLowerCase()}?company=true${purchaseTypeQuery ? `&${purchaseTypeQuery}` : ''}`
            : `${productCard.url.toLocaleLowerCase()}${purchaseTypeQuery ? `?${purchaseTypeQuery}` : ''}`;
    }

    return `${productCard.url.toLocaleLowerCase()}${initialPurchaseType ? `?purchaseType=${initialPurchaseType}` : ''}`;
};

export const mapAvailabilityFacetToToggleOptions = (facet: FacetGroup, availabilityLabels: AvailabilityLabel[]): ToggleOptionType[] => {
    const vehicleContext = 'car'; //Must eventually be provided by actual context
    return [
        ...facet.facetOptions.map((x) => {
            let label = x.key;
            const labelText = availabilityLabels.find((y) => y.availabilityKey === x.key);
            if (labelText && labelText.labelInCarContext.length > 0 && vehicleContext === 'car') {
                label = labelText.labelInCarContext;
            }
            // else if (labelText && labelText.labelInVanContext.length > 0 && vehicleContext === 'van') {
            //     label = labelText.labelInVanContext;
            // }
            return {
                label,
                value: x.filterQueryValue,
            };
        }),
    ];
};

export const handleSortingQuery = (selectedSorting: string, defaultSorting: string): void => {
    updateUrlWithQueries({ selectedSorting, defaultSorting });
};

export const updateUrlWithQueries = (sortingOptions?: sortingOptions, activeFilters?: string[], page?: string): void => {
    const splitUrl = window.location.href.split('?');
    let baseUrl = splitUrl[0]; // Changed const to let to allow modification
    if (splitUrl.length === 1 && activeFilters && activeFilters.length === 0 && !sortingOptions && !page) {
        return;
    }
    let queries = '';
    let existingParams = '';
    const urlParams = new URLSearchParams(window.location.search);
    const sortParam = urlParams.get('sort');
    const filterParams = urlParams.getAll('filter');
    const pageParam = urlParams.getAll('page');

    // Store the existing parameters in existingParams
    urlParams.forEach((value, key) => {
        if (!['sort', 'filter', 'page'].includes(key)) {
            existingParams += `${key}=${value}&`;
        }
    });

    if (activeFilters && activeFilters.length > 0) {
        if (sortParam) {
            queries = `sort=${sortParam}&`;
        }
        for (const filter of activeFilters) {
            queries = queries + 'filter=' + filter;
            if (filter !== activeFilters[activeFilters.length - 1]) {
                queries = queries + '&';
            }
        }
    } else if (sortingOptions) {
        if (sortingOptions.defaultSorting !== sortingOptions.selectedSorting) {
            queries = `sort=${sortingOptions.selectedSorting}${filterParams.length > 0 ? '&' : ''}`;
        }
        for (const filter of filterParams) {
            queries = queries + 'filter=' + filter;
            if (filter !== filterParams[filterParams.length - 1]) {
                queries = queries + '&';
            }
        }
    } else if (sortParam) {
        queries = `sort=${sortParam}`;
    }

    if (page !== '0' && !sortingOptions) {
        if (page && page.length > 0) {
            queries = queries + (queries.length > 0 ? `&page=${page}` : `page=${page}`);
        } else if (pageParam && pageParam.length > 0) {
            queries = queries + (queries.length > 0 ? `&page=${pageParam}` : `page=${pageParam}`);
        }
    }

    // Add existingParams at the end of queries, with a check to prepend '&' if necessary
    if (queries && queries.length > 0 && !queries.endsWith('&') && existingParams && existingParams.length > 0) {
        queries += '&';
    }

    // Add existingParams at the end of queries
    queries += existingParams;

    // Ensure there's no hash in baseUrl
    if (baseUrl.includes('#')) {
        baseUrl = baseUrl.split('#')[0];
    }

    router.push(
        {
            pathname: baseUrl,
            query: queries.endsWith('&') ? queries.slice(0, -1) : queries, // Remove trailing '&' if it exists
            hash: null, // Remove any hashes to prevent unintended scrolling after filters are applied
        },
        undefined,
        { shallow: true }
    );
};

export const getSortingFromUrl = (): string => {
    const urlParams = new URLSearchParams(window.location.search);
    const sortParam = urlParams.get('sort');
    return sortParam ?? '';
};

export const getPageFromUrl = (): string => {
    const urlParams = new URLSearchParams(window.location.search);
    const pageParam = urlParams.get('page');
    return pageParam ?? '';
};

export const getFacetInputTexts = (
    filterGroupTextConfiguration: Array<FilterGroupTextConfig> = [],
    facet: hesselViewModels.FacetGroup
): {
    facetLabel: string;
    facetPlaceholder: string;
} => {
    const textConfig = filterGroupTextConfiguration?.find((x) => x.filterGroupKey === facet.key);

    return {
        facetLabel: textConfig && !isNullOrEmpty(textConfig?.filterGroupLabelText) ? textConfig.filterGroupLabelText : facet.label,
        facetPlaceholder:
            textConfig && !isNullOrEmpty(textConfig?.filterGroupPlaceholder)
                ? textConfig.filterGroupPlaceholder
                : `Vælg ${facet.label.toLocaleLowerCase()}`,
    };
};

export const formatTileText = (fullQuery: string, separator = '-'): string => {
    const groupKey = fullQuery.split('__')[0];
    const value = fullQuery.split('__')[1];
    const splitValue = value.split(separator);
    if (splitValue.length === 2) {
        const convertedOne = Number(splitValue[0]);
        const convertedTwo = splitValue[1] === 'max' ? 9999999 : Number(splitValue[1]);
        if (!isNaN(convertedOne) && !isNaN(convertedTwo)) {
            const formattedOne = groupKey !== FirstYearRegFilterKey ? formatPrice(convertedOne) : convertedOne;
            const formattedTwo = groupKey !== FirstYearRegFilterKey ? formatPrice(convertedTwo) : convertedTwo;
            if (convertedOne === 0) return `max. ${formattedTwo} ${getUnitForFacetGroup(groupKey)}`;
            if (convertedTwo === 9999999) return `min. ${formattedOne} ${getUnitForFacetGroup(groupKey)}`;
            return `${formattedOne}-${formattedTwo} ${getUnitForFacetGroup(groupKey)}`;
        }
    }
    if (groupKey === 'fits' && value.split('~~').length > 0) {
        return value.split('~~').join(' ');
    }
    return value;
};

export const validateFilterTile = (productListData: ProductListApiResponse, facetMatchKey: string, tileText: string, query: string): boolean => {
    let validFacetOption = false;
    if (!validFacetOption) {
        validFacetOption = productListData.facets[facetMatchKey]?.find((f) => f.key === tileText) !== undefined;
    }
    if (!validFacetOption && facetMatchKey === ModelTitleFilterKey) {
        const brandFacet = productListData.facets[BrandFilterKey]?.filter((f) => f.selected);
        if (brandFacet) {
            validFacetOption = brandFacet.find((bf) => bf.subFacets?.find((sf) => sf.key === tileText)) !== undefined;
        }
    }
    if (!validFacetOption && facetMatchKey === FamilyFilterKey) {
        const brandFacet = productListData.facets[BrandFilterKey]?.filter((f) => f.selected);
        if (brandFacet) {
            const match = brandFacet.filter((x) => {
                if (x.selected) {
                    const selectedSubfacets = x.subFacets?.filter((y) => y.selected);
                    if (selectedSubfacets) {
                        const subfacetMatch = selectedSubfacets.find((z) => z.subFacets?.find((sf) => sf.key === tileText));
                        if (subfacetMatch) return true;
                    }
                }
                return false;
            });
            if (match && match.length > 0) {
                validFacetOption = true;
            }
        }
    }
    if (!validFacetOption && query.indexOf('__') > -1) {
        const value = query.split('__')[1];
        if (value.indexOf('-') > -1) {
            const [min, max] = value.split('-');
            const minSelected = productListData.facets[facetMatchKey]?.find((f) => f.key === min && f.selected) !== undefined;
            const maxSelected = productListData.facets[facetMatchKey]?.find((f) => f.key === max && f.selected) !== undefined;
            validFacetOption = minSelected || maxSelected;
        }
    }
    return validFacetOption;
};

export const getUnitForFacetGroup = (groupKey: string): string => {
    if (groupKey === 'specification.maximumTowingWeight') return 'kg';
    if (groupKey === 'specification.mixedDriving') return Abbreviations.KM_SLASH_L;
    if (groupKey === 'specification.range') return 'km';
    if (groupKey === 'specification.horsePowerForFilter') return 'hk';
    if (
        groupKey === 'purchaseTypes.hire.baseMonthlyPrice' ||
        groupKey === 'purchaseTypes.privateLeasing.baseMonthlyPrice' ||
        groupKey === 'operationalOrFinancialLeasingPrice' ||
        groupKey === 'purchaseTypes.financing.baseMonthlyPrice'
    )
        return Abbreviations.KR_SLASH_MD;
    if (groupKey === 'operationalOrFinancialLeasingDownPayment' || groupKey === 'purchaseTypes.cash.basePrice' || groupKey === 'price') return 'kr.';
    return '';
};

export const getDealershipsForTestDrives = (unfilteredDealerships: DealershipInformationPage[]): DealershipInformationPage[] => {
    if (unfilteredDealerships && unfilteredDealerships.length > 0) {
        return unfilteredDealerships.filter((x) => x.testDriveOpeningHours && x.testDriveOpeningHours.length > 0);
    }
    return [];
};
