import { StateMapper, ActionTypes } from 'easy-peasy';
import { hesselViewModels } from '../../../../lib/view-models';
import { ProductDetailsStoreModel } from './product-details.types';
import { _FilterKeys } from 'ts-toolbelt/out/Object/FilterKeys';
import { _Pick } from 'ts-toolbelt/out/Object/Pick';

/**
 * Get default selected tab from ownershipMode, vehicleType and available purchaseTypes
 */
export const getDefaultSelectedTab = (
    ownershipMode: hesselViewModels.OwnershipMode,
    selectedProduct: hesselViewModels.ProductDetails | undefined,
    isCompanyCar: boolean,
    leasingTabOverride?: hesselViewModels.OwnershipTab
): hesselViewModels.OwnershipTab => {
    if (!selectedProduct) {
        // This happens when we are on a non PDP page, so this value does not matter
        return 'No Value';
    }

    const { purchaseTypes } = selectedProduct;
    // If deliberately or no leasing options are available we treat it is as purchase mode.
    if (ownershipMode === 'Køb' || !isLeasingAvailable(selectedProduct, isCompanyCar)) {
        // First test if taxation is available for company cars
        if (isCompanyCar && purchaseTypes?.solutionTaxation?.show) {
            return 'Beskatningsgrundlag';
        }
        // If cash is available, select that tab
        if (purchaseTypes.cash.show) {
            return `${selectedProduct?.vehicleType === 'Van' ? 'Van' : 'Car'} Kontant`;
            // If cash is not available try for financing.
        } else if (purchaseTypes.financing.show) {
            return `${selectedProduct?.vehicleType === 'Van' ? 'Van' : 'Car'} Finansiering`;
        }
    } else {
        // Payload is 'Leasing'
        if (selectedProduct?.vehicleType === 'Van') {
            // Vans default to operational leasing if available
            // But we need to make sure tabs with Populær badge are prioritized.
            // Operationel Leasing -> for Mercedes-Benz Vans
            // Finansiel Leasing -> for Renault, Ford and Dacia Vans
            if (purchaseTypes?.operationalLeasing.show && selectedProduct?.brand === 'Mercedes-Benz') {
                return 'Operationel Leasing';
            } else if (purchaseTypes?.financialLeasing.show) {
                return 'Finansiel Leasing';
            }
        } else if (selectedProduct?.vehicleType === 'Car') {
            if (isCompanyCar) {
                if (leasingTabOverride === 'Operationel Leasing' || leasingTabOverride === 'Finansiel Leasing') {
                    return leasingTabOverride;
                }
                if (purchaseTypes?.operationalLeasing.show && selectedProduct?.brand === 'Mercedes-Benz') {
                    return 'Operationel Leasing';
                } else if (purchaseTypes?.financialLeasing.show) {
                    return 'Finansiel Leasing';
                }
            } else {
                // Cars default to HiRE if available
                if (purchaseTypes?.hire.show) {
                    return 'Car HiRE';
                } else if (purchaseTypes?.privateLeasing.show) {
                    return 'Car Privat Leasing';
                }
            }
        }
    }

    return 'Car Kontant';
};

/**
 * Get a list of available tabs for the selected product
 */
export const getAvailableTabs = (
    selectedProduct: hesselViewModels.ProductDetails | undefined,
    isCompanyCar: boolean
): hesselViewModels.OwnershipTab[] => {
    if (!selectedProduct) {
        return [];
    }

    const { purchaseTypes } = selectedProduct;
    const availableTabs: hesselViewModels.OwnershipTab[] = [];

    if (purchaseTypes?.cash.show || (isCompanyCar && purchaseTypes?.solutionTaxation.show)) {
        availableTabs.push(`${selectedProduct?.vehicleType === 'Van' ? 'Van' : 'Car'} Kontant`);
    }
    if (purchaseTypes?.financing.show) {
        availableTabs.push(`${selectedProduct?.vehicleType === 'Van' ? 'Van' : 'Car'} Finansiering`);
    }
    if (purchaseTypes?.privateLeasing.show) {
        availableTabs.push('Car Privat Leasing');
    }
    if (purchaseTypes?.operationalLeasing.show) {
        availableTabs.push('Operationel Leasing');
    }
    if (purchaseTypes?.financialLeasing.show) {
        availableTabs.push('Finansiel Leasing');
    }
    if (purchaseTypes?.hire.show) {
        availableTabs.push('Car HiRE');
    }

    return availableTabs;
};

/**
 * Check if leasing is available for the selected product
 */
export const isLeasingAvailable = (selectedProduct: hesselViewModels.ProductDetails | undefined, isCompanyCar = false): boolean => {
    if (!selectedProduct) {
        return false;
    }
    const { purchaseTypes } = selectedProduct;
    if (selectedProduct.vehicleType === 'Car' && !isCompanyCar) return purchaseTypes?.privateLeasing.show || purchaseTypes.hire.show;
    return purchaseTypes?.financialLeasing.show || purchaseTypes.operationalLeasing.show;
};

/**
 * Check if purchase is available for the selected product
 */
export const isPurchaseAvailable = (selectedProduct: hesselViewModels.ProductDetails | undefined, isCompanyCar = false): boolean => {
    if (!selectedProduct) {
        return false;
    }
    const { purchaseTypes } = selectedProduct;
    if (isCompanyCar) return purchaseTypes?.solutionTaxation?.show;
    return purchaseTypes?.cash.show || purchaseTypes.financing.show;
};

/**
 * Validate that ownershipMode and selectedTab are valid for the selected product. If not, update them.
 * NOTE: This mutates the state
 */
export const validateOwnershipModeAndSelectedTab = (
    state: StateMapper<_Pick<ProductDetailsStoreModel, _FilterKeys<ProductDetailsStoreModel, ActionTypes, 'default'>>>
): void => {
    if (state.selectedProduct) {
        const { ownershipMode, selectedTab, isCompanyCar } = state;
        // Validating selectedTab will do as it will also catch invalid ownershipMode
        if (!getAvailableTabs(state.selectedProduct, isCompanyCar).includes(selectedTab)) {
            const validOwnershipMode = ownershipMode === 'Leasing' && isLeasingAvailable(state.selectedProduct) ? 'Leasing' : 'Køb';
            state.ownershipMode = validOwnershipMode;
            state.selectedTab = getDefaultSelectedTab(validOwnershipMode, state.selectedProduct, isCompanyCar);
        }
    }
};
