export const validateTel = (tel: string): boolean =>
    /^[0-9]{8}$/.test(tel) || /^([0-9]{2}\s{1}){3}([0-9]{2}){1}$/.test(tel) || /^[0-9]{4}\s[0-9]{4}$/.test(tel) || validateTelWithCountryCode(tel);

const validateTelWithCountryCode = (tel: string): boolean => {
    return (
        /^(\+?\d{1,3}|\d{1,4})(\s)?[0-9]{8}$/.test(tel) ||
        /^(\+?\d{1,3}|\d{1,4})(\s)?([0-9]{2}\s{1}){3}([0-9]{2}){1}$/.test(tel) ||
        /^(\+?\d{1,3}|\d{1,4})(\s)?[0-9]{4}\s[0-9]{4}$/.test(tel)
    );
};

export const validateEmail = (email: string): boolean =>
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email.toLowerCase()
    );

export const validateNotEmpty = (value: string): boolean => !!value;

export const validateRegistrationNumber = (regNr: string, allowCustomPlate = false): boolean =>
    /^[a-zA-Z]{2}\s{0,1}[0-9]{5}\s*$/.test(regNr) ||
    /^[a-zA-Z]{2}\s[0-9]{2}\s{1}[0-9]{3}\s*$/.test(regNr) ||
    (allowCustomPlate && validateAsCustomerRegistrationNumber(regNr));

export const validateAsCustomerRegistrationNumber = (regNr: string): boolean => {
    return !(Number(regNr) && regNr.length > 4) && /^(?![a-zA-Z]{2}\d+$)(?=.{2,7}$)(?=.*[a-zA-Z].{4,})?[a-zA-Z0-9]+$/.test(regNr);
};

export const validateName = (name: string): boolean => {
    return name.trimEnd().split(' ').length > 1;
};

export const validateZip = (zip: string): boolean => {
    return !isNaN(Number(zip)) && zip.length === 4;
};
