import router from 'next/router';
import {
    Plp_Load_More_Context_Key,
    Plp_Load_More_Filter_From_Key,
    Plp_Load_More_No_Filter_From_Key,
    Plp_Load_More_Filter_Product_Card_Key,
    PdpPurchaseTypekey,
} from '../../constants/site-consts';
import { PromotionAvailabilityType } from '../../lib/api/models/umbraco/product-details-promotion.types';
import { hesselViewModels } from '../../lib/view-models';

export function removeLoadMoreKeys(): void {
    removePlpLoadMoreContext();
    removeStoredRequestFrom();
    removeProductCardScrollToId();
}

export function getPlpLoadMoreContext(): string | null {
    return sessionStorage.getItem(Plp_Load_More_Context_Key);
}

export function storePlpLoadMoreContext(url: string, filterConfig: string): void {
    sessionStorage.setItem(Plp_Load_More_Context_Key, `${url}||${filterConfig}`);
}

export function removePlpLoadMoreContext(): void {
    sessionStorage.removeItem(Plp_Load_More_Context_Key);
}

export function getLoadMore(): string | null {
    return sessionStorage.getItem(Plp_Load_More_Filter_From_Key);
}

export function storeLoadMore(value: string): void {
    sessionStorage.setItem(Plp_Load_More_Filter_From_Key, value);
}

export function getNoFilterLoadMore(): string | null {
    return sessionStorage.getItem(Plp_Load_More_No_Filter_From_Key);
}

export function storeNoFilterLoadMore(value: string): void {
    sessionStorage.setItem(Plp_Load_More_No_Filter_From_Key, value);
}

export function removeStoredRequestFrom(): void {
    sessionStorage.removeItem(Plp_Load_More_Filter_From_Key);
}

export function getProductCardScrollToId(): string | null {
    return sessionStorage.getItem(Plp_Load_More_Filter_Product_Card_Key);
}

export function storeProductCardScrollToId(value: string): void {
    sessionStorage.setItem(Plp_Load_More_Filter_Product_Card_Key, value);
}

export function removeProductCardScrollToId(): void {
    sessionStorage.removeItem(Plp_Load_More_Filter_Product_Card_Key);
}

export function isInLoadMoreContext(url: string, filterConfig: string): boolean {
    const prevContext = getPlpLoadMoreContext();
    if (prevContext && prevContext.indexOf('||') > -1) {
        const [prevUrl, prevConfig] = prevContext.split('||');
        if (prevUrl === url && prevConfig === filterConfig) return true;
    }
    return false;
}

/**
 * https://jira.impact.dk/browse/EJH-898
 * @param product
 * @returns
 */
export function showHigherEquipmentLabel(product: hesselViewModels.ProductDetails, selectedTab: hesselViewModels.OwnershipTab): boolean {
    const allEquipment = [
        ...product.extraEquipment[selectedTab],
        ...product.extraEquipmentPackages[selectedTab],
        ...product.standardEquipment[selectedTab],
        ...product.standardEquipmentPackages[selectedTab],
        ...product.standardEquipmentPackages[selectedTab],
    ];

    return allEquipment.some((x) => x.monthlyHirePrice === 0);
}

export function sortProductCardsByProductPrice(products: hesselViewModels.ProductCard[], price: number): hesselViewModels.ProductCard[] {
    const sortedProducts = products.sort((a, b) => {
        if (a.type === 'detailed' && b.type === 'detailed') {
            const aPrice = a.price[0]?.rawPriceValue ?? 0;
            const bPrice = b.price[0]?.rawPriceValue ?? 0;

            const aDiff = Math.abs(aPrice - price);
            const bDiff = Math.abs(bPrice - price);

            return aDiff - bDiff;
        }

        return 0;
    });

    return sortedProducts;
}

export function mapCarPromotionAvailabilityToVehicleAvailability(
    availabilityList: Array<PromotionAvailabilityType>
): Array<hesselViewModels.VehicleAvailability> {
    return availabilityList.map((availability) => {
        switch (availability) {
            case 'Bestilling':
                return 'Order';
            case 'Lager':
                return 'InStock';
            case 'Brugt':
                return 'Used';
            case 'Engros':
                return 'Engros';
            case 'Demo':
                return 'Demo';
            default: {
                const check: never = availability;
                return check;
            }
        }
    });
}

export function updateSelectedTabQuery(selectedTab: hesselViewModels.OwnershipTab): void {
    const splitUrl = window.location.href.split('?');
    let baseUrl = splitUrl[0];

    const urlParams = new URLSearchParams(window.location.search);
    const currentQueryTab = urlParams.get(PdpPurchaseTypekey);

    if (currentQueryTab === selectedTab) {
        return;
    }

    let existingParams = '';
    let queries = '';
    urlParams.forEach((value, key) => {
        if (![PdpPurchaseTypekey].includes(key)) {
            existingParams += `${key}=${value}&`;
        }
    });

    queries += `${PdpPurchaseTypekey}=${selectedTab}`;

    // Add existingParams at the end of queries, with a check to prepend '&' if necessary
    if (queries && queries.length > 0 && !queries.endsWith('&') && existingParams && existingParams.length > 0) {
        queries += '&';
    }

    // Add existingParams at the end of queries
    queries += existingParams;

    // Ensure there's no hash in baseUrl
    if (baseUrl.includes('#')) {
        baseUrl = baseUrl.split('#')[0];
    }

    router.replace(
        {
            pathname: baseUrl,
            query: queries.endsWith('&') ? queries.slice(0, -1) : queries, // Remove trailing '&' if it exists
            hash: window.location.hash,
        },
        undefined,
        { shallow: true }
    );
}

export function getInitialOwnershipMode(
    ownershipMode: hesselViewModels.OwnershipMode | undefined,
    car?: hesselViewModels.ProductDetails,
    isCompanyCar?: boolean | null,
    queryTab?: hesselViewModels.OwnershipTab | null
): hesselViewModels.OwnershipMode | undefined {
    if (!queryTab || !car) {
        return ownershipMode;
    }

    if (
        hesselViewModels.OwnershipTabArray.includes(queryTab as hesselViewModels.OwnershipTab) &&
        isTabAvailable(car, queryTab as hesselViewModels.OwnershipTab) &&
        isTabAllowed(queryTab as hesselViewModels.OwnershipTab, isCompanyCar)
    ) {
        if (
            queryTab === 'Car Finansiering' ||
            queryTab === 'Car Kontant' ||
            queryTab === 'Van Kontant' ||
            queryTab === 'Van Finansiering' ||
            queryTab === 'Beskatningsgrundlag'
        ) {
            return 'Køb';
        } else {
            return 'Leasing';
        }
    }
    return ownershipMode;
}
export function getInitialOwnershipTab(
    ownershipTab?: hesselViewModels.OwnershipTab,
    car?: hesselViewModels.ProductDetails,
    isCompanyCar?: boolean | null,
    queryTab?: hesselViewModels.OwnershipTab | null
): hesselViewModels.OwnershipTab | undefined {
    if (!queryTab || !car) {
        return ownershipTab;
    }
    if (hesselViewModels.OwnershipTabArray.includes(queryTab as hesselViewModels.OwnershipTab)) {
        if (isTabAvailable(car, queryTab as hesselViewModels.OwnershipTab) && isTabAllowed(queryTab as hesselViewModels.OwnershipTab, isCompanyCar)) {
            return queryTab as hesselViewModels.OwnershipTab;
        }
    }
    return ownershipTab;
}

const isTabAvailable = (car: hesselViewModels.ProductDetails, tab: hesselViewModels.OwnershipTab): boolean => {
    switch (tab) {
        case 'Car Privat Leasing':
            return !!car?.purchaseTypes.privateLeasing && car.purchaseTypes.privateLeasing.show;
        case 'Car HiRE':
            return !!car?.purchaseTypes.hire && car.purchaseTypes.hire.show;
        case 'Car Finansiering':
            return !!car?.purchaseTypes.financing && car.purchaseTypes.financing.show;
        case 'Car Kontant':
            return !!car?.purchaseTypes.cash && car.purchaseTypes.cash.show;
        case 'Operationel Leasing':
            return !!car?.purchaseTypes.operationalLeasing && car.purchaseTypes.operationalLeasing.show;
        case 'Finansiel Leasing':
            return !!car?.purchaseTypes.financialLeasing && car.purchaseTypes.financialLeasing.show;
        case 'Van Finansiering':
            return !!car?.purchaseTypes.financing && car.purchaseTypes.financing.show;
        case 'Van Kontant':
            return !!car?.purchaseTypes.cash && car.purchaseTypes.cash.show;
        case 'Beskatningsgrundlag':
            return !!car?.purchaseTypes.solutionTaxation && car.purchaseTypes.solutionTaxation.show;
        default:
            return false;
    }
};

const isTabAllowed = (tab: hesselViewModels.OwnershipTab, isCompanyCar?: boolean | null): boolean => {
    if (isCompanyCar === undefined || isCompanyCar === null) {
        return true;
    }
    if (isCompanyCar) {
        switch (tab) {
            case 'Operationel Leasing':
            case 'Finansiel Leasing':
            case 'Beskatningsgrundlag':
                return true;
            default:
                return false;
        }
    }

    return true;
};
