import { sharedTypes } from '..';
import { uiTypes } from '../../../..';
import { Position, SpotOverlaySetting, VerticalAlignment } from './content-spot.types';

type BaseCampaignElement<T extends 'benefitsDetails' | 'benefitsDetailsWithCTA' | 'campaignSetup'> = {
    alias: T;
    elementPosition: Position;
    verticalAlignment: VerticalAlignment;
};

export type BaseBenefit = {
    tagText: string;
    headlineText: string;
    headerType: uiTypes.HeaderType;
    headerSize: uiTypes.HeaderSize;
    price: string;
    priceSubText: string;
    benefitsList: {
        titleText: string;
    }[];
    backgroundColor: string;
    backgroundOpacity: SpotOpacity;
    textColor: string;
    horizontalLineColor: string;
};

export type SimpleBenefit = BaseCampaignElement<'benefitsDetails'> & BaseBenefit;

export type BenefitWitCta = BaseCampaignElement<'benefitsDetailsWithCTA'> & BaseBenefit & { cta?: Array<sharedTypes.MixedCta> };

export type CampaignAgreement = SimpleBenefit | BenefitWitCta;

export type SpotOpacity = 'High' | 'Medium' | 'Low';

export type CampaignDescription = BaseCampaignElement<'campaignSetup'> & {
    headlineText: string;
    headerType: uiTypes.HeaderType;
    headerSize: uiTypes.HeaderSize;
    description: string;
    textColor: string;
    ctas?: Array<sharedTypes.MixedCta>;
    disclaimerText: string;
    backgroundColor: string;
    backgroundOpacity: SpotOpacity;
};

export type FullScreenCampaignElement = CampaignAgreement | CampaignDescription;

export type SlideListItem = {
    image?: sharedTypes.Image;
    imageMobile?: sharedTypes.Image;
    video?: sharedTypes.Video;
    videoMobile?: sharedTypes.Video;
    slideFootnote?: string;

    backgroundColor?: string;

    ctaForEntireSlide: Array<sharedTypes.MixedCta>;

    elementList: FullScreenCampaignElement[];

    overlay: Array<SpotOverlaySetting>;
    alias: 'fullScreenSlide';
};

export type GlobalSlideListItem = {
    alias: 'globalFullScreenCampaignSlideElement';
    globalFullScreenCampaignSlide: {
        fullScreenCampaignSlide: SlideListItem[];
    };
};

export type FullScreenSlide = {
    slideDelayInSeconds: number;
    slideList: Array<SlideListItem>;
};

export enum UrlType {
    Internal = 0,
    External = 2,
}
